import axios from 'axios'
import { message } from 'ant-design-vue';

import store from '@/store'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 10000
})

// 请求拦截器
service.interceptors.request.use(config => {
  // TOKEN注入
  if (store.getters.token) {
    config.headers.Authorization = store.getters.token
  }
  return config
}, error => {
  return Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(resp => {
  const msg = resp.data.msg
  // 二进制数据直接返回
  if (['blob', 'arraybuffer'].includes(resp.request.responseType)) {
    return resp.data
  }
  if (resp.data.code === 102) {
    // 登录过期
    message.error('登录已过期，请重新登录')
    return Promise.reject('登录已过期，请重新登录')
  } else if (resp.data.code !== 0) {
    // 其它错误
    message.error(msg)
    return Promise.reject(msg)
  }
  return resp.data.data
}, error => {
  message.error(error)
  return Promise.reject(error)
})

export default {
  get: (url, params = {}) => service({ method: 'GET', url, params }),
  post: (url, data = {}) => service({ method: 'POST', url, data })
}