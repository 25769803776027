import { setItem } from "@/utils/storage"

export default {
  namespaced: true,
  state: {
    tabList: [],
    collapsed: false,
    refresh: false,
  },
  mutations: {
    set_refresh(state, val) {
      state.refresh = !!val
    },
    toggle_collapse(state) {
      state.collapsed = ! state.collapsed
    },
    add_tab(state, tab) {
      const isFind = state.tabList.find(i => i.path === tab.path)
      if (!isFind) {
        state.tabList.push(tab)
        setItem("tabList", state.tabList)
      }
    },
    remove_tab(state, {type, index}) {
      if (state.tabList.length <= 1) {
        return
      }
      if (type === 'index') {
        state.tabList.splice(index, 1)
      } else if (type === 'right') {
        state.tabList.splice(index + 1, state.tabList.length - index + 1)
      } else if (type === 'left') {
        state.tabList.splice(0, index)
      } else if (type === 'other') {
        state.tabList.splice(index + 1, state.tabList.length - index + 1)
        state.tabList.splice(0, index)
      }
      setItem("tabList", state.tabList)
    }
  }
}