<template>
  <div class="left-region">
    <menu-unfold-outlined v-if="$store.state.app.collapsed" class="trigger"
      @click="() => $store.commit('app/toggle_collapse')" />
    <menu-fold-outlined v-else class="trigger" @click="() => $store.commit('app/toggle_collapse')" />
    <a-breadcrumb :routes="breadRoutes"></a-breadcrumb>
  </div>
  <div class="right-menu">
    <a-dropdown>
      <div>
        <a-avatar :src="require('@/assets/avatar.png')"></a-avatar>
        <span style="padding: 0 5px;">{{ $store.getters.nickname }}</span>
        <span style="position:relative;bottom:2px;">
          <a-icon name="CaretDownOutlined" :style="{ fontSize: '10px' }"></a-icon>
        </span>
      </div>
      <template #overlay>
        <a-menu @click="onMenuClick">
          <a-menu-item key="logout">
            <a-icon name="LogoutOutlined"></a-icon>
            <span style="margin-left:5px">退出登录</span>
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

// 右侧菜单
const store = useStore()
const onMenuClick = ( {key}) => {
  if (key === 'logout') {
    store.dispatch('Logout')
  }
}

// 面包屑
const route = useRoute()
const breadRoutes = ref([])
watch(route, () => {
  breadRoutes.value = route.matched.filter(i => i?.meta.isShow).map(i => {
    i.breadcrumbName = i.meta.title
    i.children.forEach(c => c.breadcrumbName = c.meta.title)
    return i
  })
}, { immediate: true })
</script>

<style lang="scss" scoped>
.left-region {
  float: left;
  display: flex;
  height: 100%;
  align-items: center;
}
.trigger {
  font-size: 18px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}
.trigger:hover {
  color: #1890ff;
}
.right-menu {
  float: right;
  padding-right: 24px;
}
</style>