import store from '@/store'
import router from '@/router'


function checkPerm(el, binding) {
  const route = router.currentRoute.value.fullPath
  let prefix = route.split('/').filter(i => i !== '').join(':')
  if (prefix != '') prefix += ':'
  // 获取绑定的值，此处为权限
  const { value } = binding
  // 获取所有的功能指令
  const perms = store.getters.userDetail.perms
  let hasPerm = false
  // 当传入的指令集为数组时
  if (value && value instanceof Array) {
    // 匹配对应的指令
    hasPerm = value.some(v => perms.includes(perfix + v))
  } else if (value) {
    hasPerm = perms.includes(prefix + value)
  }
  // 如果无法匹配，则表示当前用户无该指令，那么删除对应的功能按钮
  if (!hasPerm) {
    el.parentNode && el.parentNode.removeChild(el)
  }
}

export default {
  // 在绑定元素的父组件被挂载后调用
  mounted(el, binding) {
    checkPerm(el, binding)
  },
  // 在包含组件的 VNode 及其子组件的 VNode 更新后调用
  update(el, binding) {
    checkPerm(el, binding)
  }
}
