import { sysUserLogin, sysUserDetail } from '@/api'
import router from '@/router'
import { getItem, setItem } from '@/utils/storage'

export default {
  namespace: true,
  state: {
    token: getItem('token') || '',
    detail: null,
  },
  mutations: {
    set_token(state, token) {
      state.token = token
      setItem("token", token)
    },
    set_detail(state, userDetail) {
      state.detail = userDetail
    }
  },
  actions: {
    async Login(store, params) {
      const resp = await sysUserLogin(params)
      store.commit('set_token', resp.token)
    },
    async Detail(store, params = {}) {
      const resp = await sysUserDetail(params)
      store.commit('set_detail', resp)
      return resp.menus
    },
    async Logout(store) {
      console.log("LOGOUT")
      store.commit('set_token', '')
      router.push('/login')
    }
  }
}