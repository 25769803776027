<template>
  <div :style="{ minHeight: '360px' }">
    <router-view v-if="!$store.state.app.refresh" v-slot="{ Component, route }">
      <transition name="fade-transform" mode="out-in">
        <keep-alive>
          <component :is="Component" :key="route.path" />
        </keep-alive>
      </transition>
    </router-view>
  </div>
</template>

<script setup>
</script>