import { createRouter, createWebHashHistory } from 'vue-router'
import AdminLayout from '@/layout/AdminLayout.vue'
import store from '@/store'

const routes = [
  {
    path: '/login',
    component: () => import('@/views/login'),
  },
]

const homeRoute = {
  path: 'home',
  name: 'home',
  meta: { title: '首页', icon: 'HomeOutlined', isShow: true },
  component: () => import("@/views/home/home")
}

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

const whiteList = ['/login']

router.beforeEach(async (to, from, next) => {
  if (!store.getters.token) {
    // 无TOKEN
    if (whiteList.includes(to.path)) {
      next()
    } else {
      next('/login')
    }
  } else {
    // 有TOKEN
    if (to.path === '/login') {
      next('/')
    } else if (store.getters.userDetail) {
      next()
    } else {
      const menus = await store.dispatch('Detail')
      menus.sort((a, b) => a.sort - b.sort)
      const routers = menusToRouter(menus)
      if (routers.length > 0) {
        router.addRoute({
          path: '/',
          name: 'adminLayout',
          component: AdminLayout,
          redirect: '/home',
          children: [homeRoute, ...routers]
        })
      }
      next(to)
    }
  }
})

const menusToRouter = (menus, parentId = 0, parentPath = '') => {
  const rtn = []
  menus.forEach(menu => {
    if (menu.parentId === parentId) {
      const path = parentPath + '/' + menu.name
      const children = menusToRouter(menus, menu.id, path)
      const menuRoute = {
        path: menu.name,
        meta: { title: menu.title, icon: menu.icon, isShow: menu.isShow === 1},
        // component: () => import('@' + menu.path),
        // component: () => import('@/views/HomeView.vue'),
        // component: (resolve) => require([`@/views${path}`], resolve),
        component: children.length === 0 ? () => require.ensure([], (require) => require(`@/views${path}`)) : null,
        children,
      }
      rtn.push(menuRoute)
    }
  })
  return rtn
}

export default router
