import { createApp, nextTick } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';
import * as Icons from '@ant-design/icons-vue'
import AIcon from '@/components/AIcon'
import installDirective from '@/directives'
// 导入全局样式
import './styles/index.scss'

const app = createApp(App)
installDirective(app)

app.provide('router', router)

app.use(store).use(router).use(Antd).mount('#app')
// 导入所有内置图标
nextTick(() => {
  app.config.globalProperties.$icons = Icons
  for (const key in Icons) {
    app.component(key, Icons[key])
  }
  app.component('AIcon', AIcon)
})
