<template>
  <a-tabs
    v-model:activeKey="activeKey"
    size="small"
    type="editable-card"
    style="background: #fff; padding: 0 10px;"
    @edit="onTabEdit"
    hideAdd
  >
    <a-tab-pane
      v-for="(tab, index) in $store.state.app.tabList"
      :key="index"
      @click="onTabClick(index)"
      :closable="$store.state.app.tabList.length > 1"
      @contextmenu="openMenu($event, index)"
    >
     <template #tab>
      <a-dropdown :trigger="['contextmenu']">
        <div>{{tab.title}}</div>
        <template #overlay v-if="activeKey === index">
          <a-menu @click="onMenuClick">
            <a-menu-item key="left">
              <a-icon name="VerticalRightOutlined"></a-icon>
              <span style="margin-left:5px">关闭左侧标签</span>
            </a-menu-item>
            <a-menu-item key="right">
              <a-icon name="VerticalLeftOutlined"></a-icon>
              <span style="margin-left:5px">关闭右侧标签</span>
            </a-menu-item>
            <a-menu-item key="other">
              <a-icon name="AimOutlined"></a-icon>
              <span style="margin-left:5px">关闭其它标签</span>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
     </template>
    </a-tab-pane>
    <template #rightExtra>
      <a-space-compact>
        <a-button size="small" @click="onRefresh">
          <template #icon>
            <a-icon name="RedoOutlined"></a-icon>
          </template>
        </a-button>
        <a-dropdown :trigger="['click']">
          <a-button size="small">
            <template #icon>
              <a-icon name="CaretDownOutlined"></a-icon>
            </template>
          </a-button>

          <template #overlay>
            <a-menu @click="onMenuClick">
              <a-menu-item key="left">
                <a-icon name="VerticalRightOutlined"></a-icon>
                <span style="margin-left:5px">关闭左侧标签</span>
              </a-menu-item>
              <a-menu-item key="right">
                <a-icon name="VerticalLeftOutlined"></a-icon>
                <span style="margin-left:5px">关闭右侧标签</span>
              </a-menu-item>
              <a-menu-item key="other">
                <a-icon name="AimOutlined"></a-icon>
                <span style="margin-left:5px">关闭其它标签</span>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </a-space-compact>
    </template>
  </a-tabs>
</template>

<script setup>
import { ref, watch, nextTick } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

// 监听路由变化
const store = useStore()
const route = useRoute()
const activeKey = ref(0)
watch(route, (to, from) => {
  store.commit('app/add_tab', {
    path: to.fullPath,
    title: to.meta.title,
  })
  const tabList = store.state.app.tabList
  activeKey.value = tabList.findIndex(i => i.path === to.fullPath)
}, { immediate: true })

const onTabClick = (e) => {
  console.log({e})
}
const onTabEdit = key => {
  store.commit('app/remove_tab', {
    type: 'index',
    index: key,
  })
  console.log('tabEdit', key, activeKey.value)
  if (key === activeKey.value) {
    if (activeKey.value > 0) {
      activeKey.value = activeKey.value - 1
    } else {
      activeKey.value = activeKey.value + 1
    }
  }
}
const router = useRouter()
watch(activeKey, (val) => {
  const tabList = store.state.app.tabList
  const tab = tabList[val]
  if (tab) {
    router.push(tab.path)
  }
})

const onMenuClick = ({key}) => {
  store.commit('app/remove_tab', {
    type: key,
    index: activeKey.value
  })
}

const onRefresh = () => {
  store.commit('app/set_refresh', true)
  nextTick(() => {
    store.commit('app/set_refresh', false)
  })
}
</script>

<style lang="scss" scoped>
::v-deep .ant-tabs-nav {
  margin-bottom: 0;
}
</style>