import request from './request'

// 用户接口
export const sysUserLogin = (params) => request.post('/adm/sys/user/login', params)
export const sysUserDetail = (params) => request.post('/adm/sys/user/detail', params)
export const sysUserCaptcha = (params) => request.post('/adm/sys/user/captcha', params)
export const sysUserPage = (params) => request.post('/adm/sys/user/page', params)
export const sysUserCreate = (params) => request.post('/adm/sys/user/create', params)
export const sysUserUpdate = (params) => request.post('/adm/sys/user/update', params)
export const sysUserDelete = (params) => request.post('/adm/sys/user/delete', params)
// 菜单接口
export const sysMenuList = (params) => request.post('/adm/sys/menu/list', params)
export const sysMenuCreate = (params) => request.post('/adm/sys/menu/create', params)
export const sysMenuUpdate = (params) => request.post('/adm/sys/menu/update', params)
export const sysMenuDelete = (params) => request.post('/adm/sys/menu/delete', params)
// 角色接口
export const sysRolePage = (params) => request.post('/adm/sys/role/page', params)
export const sysRoleList = (params) => request.post('/adm/sys/role/list', params)
export const sysRoleCreate = (params) => request.post('/adm/sys/role/create', params)
export const sysRoleUpdate = (params) => request.post('/adm/sys/role/update', params)
export const sysRoleDelete = (params) => request.post('/adm/sys/role/delete', params)
// 图片接口
export const sysImageToken = (params) => request.post('/adm/sys/image/token', params)

// 游戏接口
export const apiGamePage = (params) => request.post('/adm/api/game/page', params)
export const apiGameCreate = (params) => request.post('/adm/api/game/create', params)
export const apiGameUpdate = (params) => request.post('/adm/api/game/update', params)
export const apiGameDelete = (params) => request.post('/adm/api/game/delete', params)

// 首页推荐
export const apiAdvHomePage = (params) => request.post('/adm/api/adv/home/page', params)
export const apiAdvHomeCreate = (params) => request.post('/adm/api/adv/home/create', params)
export const apiAdvHomeUpdate = (params) => request.post('/adm/api/adv/home/update', params)
export const apiAdvHomeDelete = (params) => request.post('/adm/api/adv/home/delete', params)

// 特色推荐
export const apiAdvFeaturePage = (params) => request.post('/adm/api/adv/feature/page', params)
export const apiAdvFeatureCreate = (params) => request.post('/adm/api/adv/feature/create', params)
export const apiAdvFeatureUpdate = (params) => request.post('/adm/api/adv/feature/update', params)
export const apiAdvFeatureDelete = (params) => request.post('/adm/api/adv/feature/delete', params)

// 聚焦推荐
export const apiAdvSpotlightPage = (params) => request.post('/adm/api/adv/spotlight/page', params)
export const apiAdvSpotlightCreate = (params) => request.post('/adm/api/adv/spotlight/create', params)
export const apiAdvSpotlightUpdate = (params) => request.post('/adm/api/adv/spotlight/update', params)
export const apiAdvSpotlightDelete = (params) => request.post('/adm/api/adv/spotlight/delete', params)