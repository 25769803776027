<template>
  <a-menu
    theme="dark"
    mode="inline"
    @click="onMenuClick"
    v-model:selectedKeys="selectedKeys"
    v-model:openKeys="openKeys"
  >
    <menu-item
      v-for="item in routes"
      :key="item.path"
      :route="item"
    ></menu-item>
  </a-menu>
</template>

<script setup>
import MenuItem from './MenuItem.vue';
import { computed, watch, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { generateMenus } from '@/utils/route'

// 计算路由表结构
const router = useRouter()
const routes = computed(() => {
  const filterRoutes = router.getRoutes().find(i => i.path === '/')
  return generateMenus([filterRoutes])
})

// 计算高亮menu
const openKeys = ref([])
const selectedKeys = ref([])
const route = useRoute()
watch(route, val => {
  selectedKeys.value = [val.path]
  openKeys.value = val.matched.filter(i => i.meta?.isShow && i.children.length > 0).map(a => a.path)
}, {immediate: true})

// 路由跳转
const onMenuClick = ({ key }) => {
  router.push(key)
}
</script>

<style>
</style>